import React from 'react'
import './TourneyCard.css'

const TourneyCard = ({ data }) => {
    return (
        <div className='tourney-card'>
            <figure className='tourney-card-img'>
                
                <img src={(data.images.length > 0 ?data.images[0].url:require('../../img/sb.png'))} alt="..." />
            </figure>
            <a className='tourney-card-data' href={data.url}>

                <h1>{data.name}</h1>
                {(data.city === null) ? '' : <span>Ubicacion: {data.city}</span>}

                <span>Participantes: {data.numAttendees} </span>
                <span>{data.isRegistrationOpen ? <span style={{ color: "#42d134", fontWeight: 600 }}>Registro Activo</span> : <span style={{ color: "red", fontWeight: 600 }}>Registro Finalizado</span>}</span>


            </a>
            <a className='more-arrow' href={data.url}><i className='bi-caret-right-fill'></i> </a>
        </div>
    )
}

export default TourneyCard