import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='Footer-wrapper'>
        <h1>Contactanos</h1>
        <a href='mailto:smashgtmail@gmail.com'>smashgtmail@gmail.com</a>
        <a href='https://www.facebook.com/SmashGTOficial/'>Facebook</a>
        <a href='https://www.instagram.com/smashgt/'>Instagram</a>
        <a href='https://discord.com/invite/NMYcdaw/'>Discord</a>
        <a href='https://twitter.com/SmashGtOficial/'>Twitter</a>
    </div>
  )
}

export default Footer