import React, { useState } from 'react'
import TopCard from '../../components/TopCard/TopCard';
import './Top20.css'
import topPlayersData from '../../data/topPlayersData'
import { Helmet } from 'react-helmet';

const Top20 = () => {
    const [data, setData] = useState(topPlayersData)

    const [selection, setelection] = useState([
        { title: "Julio - Diciembre 2022", backgroundImgName: "https://i.imgur.com/pPdsbCM.png" },
        { title: "Enero - Junio 2022", backgroundImgName: "https://i.imgur.com/hDWNncT.jpg" },

    ])

    const [selected, setSelected] = useState(0);

    const selectionOnChange = (e) => {
        setSelected(e.target.value);
    }

    return (
        <>
            <Helmet>
                <title>Top 20</title>
                <meta name="description" content="Aqui encontraras el top de jugadores por cada temporada." />
            </Helmet>
            <div>
                <div className='top20-welcome'>
                    <h1>Top 20 </h1>
                    <hr></hr>
                    <p>Aqui encontraras el top de jugadores por cada temporada.</p>

                </div>
                <div className='top20-selection-container'>
                    <div className='top20-selection'>
                        <h2>Selecciona una temporada:</h2>
                        <select id="selections" onChange={e => selectionOnChange(e)}>
                            {selection.map((el, i) => {
                                return <option value={i} key={el.title}>{el.title}</option>
                            })}
                        </select>
                    </div>


                </div>
                <div className='top20-cards-wrapper'>
                    {data[selected].map((el, i) => { return (<TopCard userSelection={selection[selected]} data={el} i={i} key={'TopC-' + i} />) })}
                </div>
            </div>
        </>
    )
}

export default Top20