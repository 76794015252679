const data = [
    [
        {
            id: 'top2020222-1',
            name: 'SHINYMARK',
            winrate: '93%',
            best:
                [
                    'TOP 1 Rankings #5',
                ],
            url: 'https://www.start.gg/user/1cbc6e18',
            img: 'https://i.imgur.com/2W8Zpr1.jpg'
        },
        {
            id: 'top2020222-2',
            name: 'CRISAM',
            winrate: '75%',
            best:
                [
                    'TOP 1 Rankings #1'
                ],
            url: 'https://www.start.gg/user/358b1053',
            img: 'https://i.imgur.com/gRWkczu.jpg'

        },
        {
            id: 'top2020222-3',
            name: 'HAUNTER',
            winrate: '72%',
            best:
                [
                    'TOP 2 Rankings #2'
                ],
            url: 'https://www.start.gg/user/cbc975e9',
            img: 'https://i.imgur.com/yvcS6CH.jpg'
        },
        {
            id: 'top2020222-4',
            name: 'DRAGONSLAYER',
            winrate: '72%',
            best:
                [
                    'TOP 2 Rankings #2'
                ],
            url: 'https://www.start.gg/user/51cde08f',

            img: 'https://i.imgur.com/oTx5O8J.jpg'
        },
        {
            id: 'top2020222-5',
            name: 'BURNLIGHT',
            winrate: '68%',
            best:
                [
                    'TOP 3 Rankings #1'
                ],
            url: 'https://www.start.gg/user/e06c9e10',

            img: 'https://i.imgur.com/Qk8UP8Y.jpg'
        }
        ,
        {
            id: 'top2020222-6',
            name: 'KOS-MOS',
            winrate: '67%',
            best:
                [
                    'TOP 4 Rankings #1'
                ],
            url: 'https://www.start.gg/user/59451a63'
            ,

            img: 'https://i.imgur.com/9q0i8ZS.jpg'
        }
        
          
    ],
    [
        {
            id: 'top2020221-1',
            name: 'SHINYMARK',
            winrate: '91%',
            best:
                [
                    'TOP 1 RANK UP! #6',
                ],
            url: 'https://www.start.gg/user/1cbc6e18',
            img: 'https://i.imgur.com/2W8Zpr1.jpg'
        },
        {
            id: 'top2020221-2',
            name: 'CRISAM',
            winrate: '84%',
            best:
                [
                    'TOP 1 RANK UP! #2'
                ],
            url: 'https://www.start.gg/user/358b1053',
            img: 'https://i.imgur.com/gRWkczu.jpg'

        },
        {
            id: 'top2020221-3',
            name: 'HAUNTER',
            winrate: '71%',
            best:
                [
                    'TOP 2 RANK UP! #5'
                ],
            url: 'https://www.start.gg/user/cbc975e9',
            img: 'https://i.imgur.com/BiHQdRm.jpg'
        },
        {
            id: 'top2020221-4',
            name: 'TACOBROS',
            winrate: '72%',
            best:
                [
                    'TOP 2 RANK UP! #3'
                ],
            url: 'https://www.start.gg/user/6add8de4',

            img: 'https://i.imgur.com/NRp49dK.jpg'
        },
        {
            id: 'top2020221-5',
            name: 'BURNLIGHT',
            winrate: '67%',
            best:
                [
                    'TOP 4 RANK UP! #4'
                ],
            url: 'https://www.start.gg/user/e06c9e10',

            img: 'https://i.imgur.com/Qk8UP8Y.jpg'
        }
        ,
        {
            id: 'top2020221-6',
            name: 'KOS-MOS',
            winrate: '67%',
            best:
                [
                    'TOP 5 RANK UP! #4'
                ],
            url: 'https://www.start.gg/user/59451a63'
            ,

            img: 'https://i.imgur.com/9q0i8ZS.jpg'
        }
        ,
        {
            id: 'top2020221-7',
            name: 'DAAK',
            winrate: '66%',
            best:
                [
                    'TOP 3 RANK UP! #1'
                ],
            url: 'https://www.start.gg/user/209efc32',
            img: 'https://i.imgur.com/mURvMnt.jpg'
        }
        ,
        {
            id: 'top2020221-8',
            name: 'TACO',
            winrate: '67%',
            best:
                [
                    'TOP 5 RANK UP! #5'
                ],
            url: 'https://www.start.gg/user/f84b4426',
            img: 'https://i.imgur.com/EGg6LvJ.jpg'
        }
        ,
        {
            id: 'top2020221-9',
            name: 'BANANO',
            winrate: '69%',
            best:
                [
                    'TOP 4 RANK UP! #2'
                ],
            url: 'https://www.start.gg/user/10094490',

            img: 'https://i.imgur.com/D8OwiwP.jpg'
        }
        ,
        {
            id: 'top2020221-10',
            name: 'GRENO',
            winrate: '68%',
            best:
                [
                    'TOP 3 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/8a4a3137',

            img: 'https://i.imgur.com/xC005tl.jpg'
        }
        ,
        {
            id: 'top2020221-11',
            name: 'DRAGONSLAYER',
            winrate: '67%',
            best:
                [
                    'TOP 9 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/51cde08f',

            img: 'https://i.imgur.com/oTx5O8J.jpg'
        }
        ,
        {
            id: 'top2020221-12',
            name: 'ST. KRITAS',
            winrate: '60%',
            best:
                [
                    'TOP 8 RANK UP! #3'
                ],
            url: 'https://www.start.gg/user/86cf91f5',

            img: 'https://i.imgur.com/uSu0JhQ.jpg'
        }
        ,
        {
            id: 'top2020221-13',
            name: 'ALE',
            winrate: '60%',
            best:
                [
                    'TOP 5 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/36fe4a93',

            img: 'https://i.imgur.com/ohsoQy1.jpg'
        }
        ,
        {
            id: 'top2020221-14',
            name: 'GRAVITY',
            winrate: '52%',
            best:
                [
                    'TOP 5 RANK UP! #1'
                ],
            url: 'https://www.start.gg/user/355e224c',

            img: 'https://i.imgur.com/Am0IX0q.jpg'
        }
        ,
        {
            id: 'top2020221-15',
            name: 'CHARLS',
            winrate: '57%',
            best:
                [
                    'TOP 8 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/da517d1b',

            img: 'https://i.imgur.com/eiuMoTR.jpg'
        }
        ,
        {
            id: 'top2020221-16',
            name: 'LANGDON',
            winrate: '50%',
            best:
                [
                    'TOP 8 RANK UP! #4'
                ],
            url: 'https://www.start.gg/user/958028f0',

            img: 'https://i.imgur.com/860tbm5.jpg'
        }
        ,
        {
            id: 'top2020221-17',
            name: 'F.E',
            winrate: '56%',
            best:
                [
                    'TOP 5 RANK UP! #6'
                ],
            url: '#',

            img: 'https://i.imgur.com/JHpFveY.jpg'
        }
        ,
        {
            id: 'top2020221-18',
            name: 'FUNYARINPA',
            winrate: '65%',
            best:
                [
                    'TOP 5 RANK UP! #2'
                ],
            url: 'https://www.start.gg/user/2df8b14f',

            img: 'https://i.imgur.com/1zBV2Ec.jpg'
        }
        ,
        {
            id: 'top2020221-19',
            name: 'NAITSIRC',
            winrate: '71%',
            best:
                [
                    'TOP 8 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/f95613d9',

            img: 'https://i.imgur.com/SQiBf9w.jpg'
        }
        ,
        {
            id: 'top2020221-20',
            name: 'ANDERWEBOS',
            winrate: '71%',
            best:
                [
                    'TOP 8 RANK UP! #6'
                ],
            url: 'https://www.start.gg/user/f1f833eb',

            img: 'https://i.imgur.com/my1bOKP.jpg'
        }
    ]
]

export default data;
