import React from 'react'
import { Helmet } from 'react-helmet';
import './Home.css'

const Home = () => {
  return (

    <>
      <Helmet>
        <title>Smash Bros Guatemala.</title>
        <meta name="description" content="Esta es la página oficial de Smash Bros. Guatemala. Somos un grupo de jugadores enfocados en fomentar el desarrollo de la comunidad competitiva de Super Smash Bros. Ultimate en Guatemala." />
      </Helmet>
      <div className="home-social">
        <div className="home-logo-container">
          <a className='home-logo-item' href='https://www.facebook.com/SmashGTOficial/'>
            <i className='bi-facebook' ></i>
          </a>
          <a className='home-logo-item' href='https://www.instagram.com/smashgt/'>
            <i className='bi-instagram'></i>
          </a>
          <a className='home-logo-item' href='https://discord.com/invite/NMYcdaw/'>
            <i className='bi-discord'></i>
          </a>
          <a className='home-logo-item' href='https://twitter.com/SmashGtOficial/'>
            <i className='bi-twitter'></i>
          </a>
          <a className='home-logo-item' href='https://www.youtube.com/channel/UC4fs7e4zjlppBnaCe6OyRHA'>
            <i className='bi-youtube'></i>
          </a>

        </div>
      </div>
      <div className='home-content-welcome'>
      <h1>Bienvenidos!</h1>
        <p>Esta es la página oficial de Smash Bros. Guatemala</p>
      </div>
      <div className='home-content-about-us'>
        <h1>Quienes somos</h1>
        <hr></hr>
        <p>Somos un grupo de jugadores enfocados en fomentar el desarrollo de la comunidad competitiva de Super Smash Bros. Ultimate en Guatemala.</p>
      </div>
      <div className="mapouter">
        <div className="home-location">
          <h1>Ubicaciones </h1>
          <hr></hr>
          <p>Actualmente nuestros torneos son realizados en Fantasiland, San Cristobal.</p>
          <iframe
            id="gmap_canvas"
            name="gmap_canvas"
            src="https://maps.google.com/maps?q=fantasiland&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameBorder={0}
            scrolling="no"
          />
        </div>




      </div>

      {/*<div className='home-content-right'>
        <h1>derecha</h1>
        <p>texto escrito a la derecha pa probar</p>
      </div> */}
    </>

  )
}

export default Home