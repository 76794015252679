import React from 'react'
import './TopCard.css'
const TopCard = ({userSelection, data, i }) => {
  return (
    <a className='top-card-container' href={data.url} style={{background: "url("+userSelection.backgroundImgName + ")"  }} >
      <figure>
        <img src={data.img} alt="..." />
      </figure>
      
      <hr></hr>
      <h1 className={ ((data.name.length>10) ?'top-card-title-special':'')}>{"#" + (i + 1) + " - " + data.name}</h1>
      <hr></hr>
      <div className='top-card-info-container'>
        <div >
          <p >Mejor resultado: <span className='top-card-best'>{data.best[0]}</span></p>
          <p>Winrate: <span className='top-card-winrate'>{data.winrate}</span></p>
        </div>
        <div>
          {/*mains side*/}
        </div>
      </div>
    </a>
  )
}

export default TopCard