import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Helmet } from 'react-helmet';
import TourneyCard from '../../components/TourneyCard/TourneyCard';
import './Tourney.css'
const SMASHGAMECODE = 1386;
const URLAPI = "https://api.start.gg/gql/alpha";
const query = `query TournamentsByVideogame( $videogameId: ID, $countryCode: String) {
  tournaments(query: {
    perPage: 50
    page: 1
    sortBy: "startAt desc"
    filter: {
      countryCode: $countryCode
      upcoming: false
      ownerId:106130
      videogameIds: [
        $videogameId
      ]
    }
  })  {
    nodes {
      name
      isRegistrationOpen
      numAttendees
      city
      countryCode
      images {
        url
      }
      owner {
        id
      }
      url(relative:false)
    }
  }
}`;
const variables = {
  videogameId: SMASHGAMECODE,
  ownerId: 106130
};
const config = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_smashgg}`,
  }
}

const Tourney = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)


  useEffect(() => {
    axios.post(URLAPI, { query, variables }, config)
      .then(response => setData(response.data.data.tournaments.nodes)).catch(error => setError(error))
  }, [])


  return (
    <>
      <Helmet>
        <title>Torneos</title>
        <meta name="description" content="Aqui encontraras los torneos que hemos organizado." />
      </Helmet>
      <div>
        <div className='tourney-content-welcome'>
          <h1>Torneos </h1>
          <hr></hr>
          <p>Aqui encontraras los torneos que hemos organizado.</p>
        </div>
        <div className='tourney-cards-wrapper'>
          {data.map((el, i) => { return (<TourneyCard data={el} key={'TC-' + i} />) })}
        </div>
      </div>
    </>

  )
}

export default Tourney