import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';



import Home from '../pages/Home/Home';
import Top20 from '../pages/Top20/Top20';
import Tourney from '../pages/Tourney/Tourney';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/torneos" element={<Tourney/>} />
                <Route exact path="/top" element={<Top20 />} />

            </Routes>

        </BrowserRouter>
    )
}

export default AppRoutes